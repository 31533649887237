import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section, Button, Image } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"faq"} />
		<Helmet>
			<title>
				SSS | Sunset Ridge Golf Kulübü
			</title>
			<meta name={"description"} content={"Golf Yolculuğunuzun Sırlarını Açın - Sizin Sorularınız, Bizim Görüşlerimiz"} />
			<meta property={"og:title"} content={"SSS | Sunset Ridge Golf Kulübü"} />
			<meta property={"og:description"} content={"Golf Yolculuğunuzun Sırlarını Açın - Sizin Sorularınız, Bizim Görüşlerimiz"} />
			<meta property={"og:image"} content={"https://bosphorusbeauty.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://bosphorusbeauty.com/img/5950392.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://bosphorusbeauty.com/img/5950392.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://bosphorusbeauty.com/img/5950392.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://bosphorusbeauty.com/img/5950392.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://bosphorusbeauty.com/img/5950392.png"} />
			<meta name={"msapplication-TileImage"} content={"https://bosphorusbeauty.com/img/5950392.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="100px 0 100px 0" quarkly-title="FAQ-10">
			<Box min-width="100px" min-height="100px" display="flex" lg-flex-direction="column">
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 600 36px/1.2 --fontFamily-sans" color="--darkL1">
						Yeşiller Arasında Gezinmek
					</Text>
					<Text
						margin="0px 0px 70px 0px"
						font="normal 300 20px/1.5 --fontFamily-sansHelvetica"
						color="#555a5f"
						sm-margin="0px 0px 50px 0px"
						lg-margin="0px 0px 50px 0px"
					>
						Sunset Ridge Golf Club'da bir golf deneyimine hazırlanırken, bizimle geçirdiğiniz zamanın her vuruş kadar keyifli olmasını sağlamak için bazı yaygın soruların yanıtlarını derledik.
					</Text>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="block"
					grid-template-columns="repeat(2, 1fr)"
					grid-gap="50px 50px"
					md-grid-template-columns="1fr"
					sm-grid-gap="35px 0"
					padding="0px 0px 0px 70px"
					lg-padding="0px 0px 0px 0"
				>
					<Box min-width="100px" min-height="100px" margin="0px 0px 35px 0px">
						<Text margin="0px 0px 12px 0px" font="normal 500 22px/1.2 --fontFamily-sans" color="--darkL1">
							Ne tür golf sahaları sunuyorsunuz?
						</Text>
						<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
							Sunset Ridge, her beceri seviyesinden golfçüye hitap edecek şekilde tasarlanmış, zorlu ve ödüllendirici turlar sağlayan çeşitli şampiyona sahaları sunmaktadır.
						</Text>
					</Box>
					<Box min-width="100px" min-height="100px" margin="0px 0px 35px 0px">
						<Text margin="0px 0px 12px 0px" font="normal 500 22px/1.2 --fontFamily-sans" color="--darkL1">
							Akşam golfü seçeneği var mı?
						</Text>
						<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
							Cybersecurity refers to the practice of protecting computer systems and networks from unauthorized access, theft, damage, or disruption.
						</Text>
					</Box>
					<Box min-width="100px" min-height="100px" margin="0px 0px 35px 0px">
						<Text margin="0px 0px 12px 0px" font="normal 500 22px/1.2 --fontFamily-sans" color="--darkL1">
							Elbette var! Akşam golfünün büyüsünü muhteşem bir gün batımının fonunda yaşamanıza olanak tanıyan alacakaranlık tee zamanları sunuyoruz.
						</Text>
						<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
							Skills needed for IT jobs vary depending on the specific role, but generally include technical knowledge, problem-solving abilities, communication skills, attention to detail, and the ability to work well in a team.
						</Text>
					</Box>
					<Box min-width="100px" min-height="100px" margin="0px 0px 35px 0px">
						<Text margin="0px 0px 12px 0px" font="normal 500 22px/1.2 --fontFamily-sans" color="--darkL1">
							Üye olmanın avantajları nelerdir?
						</Text>
						<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
							Sunset Ridge üyeliği, öncelikli tee zamanları, özel etkinliklere erişim ve sadece üyelere özel avantajlar gibi ayrıcalıklarla birlikte gelir.
						</Text>
					</Box>
					<Box min-width="100px" min-height="100px" margin="0px 0px 35px 0px">
						<Text margin="0px 0px 12px 0px" font="normal 500 22px/1.2 --fontFamily-sans" color="--darkL1">
							Tesisinizde yemek seçenekleri var mı?
						</Text>
						<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
							Kulüp binamız, tur sonrası dinlenmek için mükemmel olan sıcak bir atmosferde nefis gurme yemekler ve serinletici içecekler sunmaktadır.
						</Text>
					</Box>
					<Box min-width="100px" min-height="100px" margin="0px 0px 35px 0px">
						<Text margin="0px 0px 12px 0px" font="normal 500 22px/1.2 --fontFamily-sans" color="--darkL1">
							Golf temel malzemeleri için bir profesyonel mağaza var mı?
						</Text>
						<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
							Personelimizin uzman tavsiyeleriyle en kaliteli golf ekipmanlarını, kıyafetlerini ve aksesuarlarını bulabileceğiniz Pro Shop'umuzu keşfedin.
						</Text>
					</Box>
					<Box min-width="100px" min-height="100px" margin="0px 0px 35px 0px">
						<Text margin="0px 0px 12px 0px" font="normal 500 22px/1.2 --fontFamily-sans" color="--darkL1">
							Golf deneyimimi kişiselleştirebilir miyim?
						</Text>
						<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
							Kesinlikle! İster özel bir kutlama ister bir grup gezisi için olsun, golf maceranızı özelleştirmeyi görüşmek için ekibimizle iletişime geçin.
						</Text>
					</Box>
					<Box min-width="100px" min-height="100px">
						<Text margin="0px 0px 12px 0px" font="normal 500 22px/1.2 --fontFamily-sans" color="--darkL1">
							Sunset Ridge'de etkinlik veya turnuvalara ev sahipliği yapabilir miyim?
						</Text>
						<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
							Elbette! Golf etkinliklerinizi veya turnuvalarınızı bizimle birlikte planlayabilirsiniz ve sahalarımızda unutulmaz anlar yaratmanıza yardımcı oluruz.
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="90px 0 100px 0" quarkly-title="List-5">
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				grid-gap="56px 34px"
				lg-grid-template-columns="repeat(2, 1fr)"
				sm-grid-template-columns="1fr"
				sm-grid-gap="36px 0"
				md-grid-gap="50px 0"
				margin="0px 130px 0px 130px"
				lg-margin="0px 0 0px 0"
				md-grid-template-columns="1fr"
			>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					flex-direction="column"
					padding="0px 50px 0px 0px"
					align-items="flex-start"
					md-padding="0px 0 0px 0px"
				>
					<Text margin="0px 0px 25px 0px" font="normal 500 28px/1.2 --fontFamily-sans" md-margin="0px 0px 20px 0px">
						Golfle İlgili Sorularınız, Uzmanlığımız
					</Text>
					<Text margin="0px 0px 35px 0px" font="normal 300 18px/1.5 --fontFamily-sansHelvetica" color="#474a4d" md-margin="0px 0px 25px 0px">
						Sorunuz burada ele alınmadıysa, ekibimize ulaşmaktan çekinmeyin. Olağanüstü bir golf deneyimi için ihtiyacınız olan tüm bilgileri size sağlamaya kendimizi adadık.
					</Text>
					<Button
						background="#0438cd"
						href="/contacts"
						type="link"
						text-decoration-line="initial"
						padding="11px 28px 11px 28px"
						border-radius="25px"
					>
						Kişiler
					</Button>
				</Box>
				<Image src="https://bosphorusbeauty.com/img/8.jpg" display="block" width="100%" md-order="-1" />
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});